import React, { Component, RefObject, useEffect, useRef, useState } from "react"
import Leaflet from "leaflet"
import {
    MapContainer,
    Map,
    TileLayer,
    Popup,
    CircleMarker,
    Marker,
} from "react-leaflet"
import "../../../../node_modules/leaflet/dist/leaflet.css"
import LoadingErrorHttp from "../../LoadingErrorHttp"
import { Spinner } from "react-awesome-spinners"

Leaflet.Icon.Default.imagePath = "../node_modules/leaflet"

import onlineMarker from "../../../assets/images/markers/online_marker.png"
import offlineMarker from "../../../assets/images/markers/offline_marker.png"
import selectedMarker from "../../../assets/images/markers/selected_marker.png"
import useWindowDimensions from "src/hooks/useWindowDimensions"

import { createAuthHeader } from "../../../helpers/logged_in_user"
import { useFetchWithAbort } from "src/hooks/useFetchWithAbort"
import { Card, CardBody } from "reactstrap"
import ErrorHttp from "src/pages/ErrorHttp"
import { Navigate } from "react-router-dom"

delete Leaflet.Icon.Default.prototype._getIconUrl

Leaflet.Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
})

const onlineMarkerIcon = Leaflet.icon({
    iconUrl: onlineMarker, // or an external URL
    iconSize: [10, 10], // [width, height]
    iconAnchor: [5, 10], // [horizontal, vertical]
})

const offlineMarkerIcon = Leaflet.icon({
    iconUrl: offlineMarker, // or an external URL
    iconSize: [10, 10], // [width, height]
    iconAnchor: [5, 10], // [horizontal, vertical]
})

const selectedMarkerIcon = Leaflet.icon({
    iconUrl: selectedMarker, // or an external URL
    iconSize: [12, 12], // [width, height]
    iconAnchor: [5, 10], // [horizontal, vertical]
})

interface mapinterface {
    devicesData: any
    selectedDeviceRowId: number | null
    callbackPointSelection: any
    t: any
}

function OurPopup({ oneDevice, t }: any) {
    return (
        <Popup>
            {t("Device")}: <b>{oneDevice.id ?? t("<unknown>")}</b>
            <br />
            {t("Id")}: {oneDevice.device ?? t("<unknown>")}
            <br />
            {t("LastSeen")}: {oneDevice.lastSeen ?? t("<unknown>")}
        </Popup>
    )
}

function OurMarker({
    oneDevice,
    isSelected,
    rowId,
    handleMarkerClick,
    t,
}: any) {
    if (oneDevice.lat == null || oneDevice.lon == null) {
        return <></>
    }

    if (!isSelected) {
        return (
            <Marker
                key={oneDevice.device}
                position={[oneDevice.lat, oneDevice.lon]}
                icon={oneDevice.online ? onlineMarkerIcon : offlineMarkerIcon}
                eventHandlers={{
                    click: (e: any) => handleMarkerClick(e, rowId),
                }}
            >
                <OurPopup oneDevice={oneDevice} t={t} />
            </Marker>
        )
    }
    return (
        <>
            <Marker
                key={oneDevice.device}
                position={[oneDevice.lat, oneDevice.lon]}
                icon={selectedMarkerIcon}
            >
                <OurPopup oneDevice={oneDevice} t={t} />
            </Marker>
            <CircleMarker
                key={`circle-${oneDevice.device}`}
                center={[oneDevice.lat, oneDevice.lon]}
                fillColor="#fafad2"
                color="orange"
                fillOpacity="0.4"
                radius={20}
            ></CircleMarker>
        </>
    )
}

function MapVectorLayers({
    devicesData,
    selectedDeviceRowId,
    callbackPointSelection,
    t,
}: mapinterface) {
    const { width, height } = useWindowDimensions()

    const heightWithoutMap = 180

    const minMapHeight = 300

    const mapHeight = Math.max(minMapHeight, height - heightWithoutMap)

    const {
        isLoading,
        data: state,
        error,
    } = useFetchWithAbort(`/api/global-map`, createAuthHeader("displayMaps"))

    const handleMarkerClick = (e: any, id: number) => {
        callbackPointSelection(id)
    }

    if (isLoading) {
        return <Spinner />
    }

    if (error) {
        if (error?.status == 401) {
            return <Navigate to="/logout" />
        }
        if (error?.status == 403) {
            return <ErrorHttp code="403" permission="displayMaps" />
        }
        return <ErrorHttp code={error?.status ?? `500`} />
    }

    return (
        <Card
            style={{
                height: mapHeight,
                maxHeight: mapHeight,
            }}
        >
            <MapContainer
                center={[state.lat, state.lon]}
                zoom={state.zoom}
                style={{
                    height: "100%",
                }}
            >
                <TileLayer
                    attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {devicesData.map((oneDevice: any, index: number) => (
                    <OurMarker
                        key={oneDevice.device}
                        rowId={index}
                        isSelected={index == selectedDeviceRowId}
                        oneDevice={oneDevice}
                        handleMarkerClick={handleMarkerClick}
                        t={t}
                    />
                ))}
            </MapContainer>
        </Card>
    )
}

export default MapVectorLayers
