import PropTypes from "prop-types"
import React from "react"
import { Link } from "react-router-dom"
import { Col, Container, Row } from "reactstrap"

//import images
import error from "../assets/images/error-img.png"

//i18n
import { withTranslation } from "react-i18next"

const ErrorHttp = (props: any) => {
    const { permission, code, t } = props
    return (
        <React.Fragment>
            <div className="my-5 pt-5">
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className="text-center mb-5">
                                <h1 className="display-1 fw-semibold">
                                    {code[0]}
                                    <span className="text-primary mx-2">
                                        {code[1]}
                                    </span>
                                    {code[2]}
                                </h1>
                                <h4 className="text-uppercase">
                                    {t("Error" + code)}{" "}
                                    {permission == null ? "" : "-"}{" "}
                                    {t(permission)}
                                </h4>
                                <div className="mt-5 text-center">
                                    <Link
                                        className="btn btn-primary"
                                        to="/dashboard"
                                    >
                                        {t("BackToMain")}
                                    </Link>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <div className="row justify-content-center">
                        <div className="col-md-10 col-xl-8">
                            <div>
                                <img src={error} alt="" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withTranslation()(ErrorHttp)
