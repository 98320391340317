import React, { useEffect, useState } from "react"

import {
    useTable,
    useFilters,
    useSortBy,
    useGlobalFilter,
    useAsyncDebounce,
} from "react-table"

import { Badge, Card, CardBody, CardHeader } from "reactstrap"
import { CSVLink } from "react-csv"
import jsPDF from "jspdf"
import "jspdf-autotable"
import { withTranslation } from "react-i18next"

import { formatDateToLocalTime } from "../../helpers/format_date"
import { openSansFontTTF } from "./font"
import useWindowDimensions from "src/hooks/useWindowDimensions"
import {
    checkPermissions,
    haspermSendMailReports,
} from "../../helpers/permission_check"
import MailingTrigger from "./MailingTrigger"

interface Globalfilter {
    preGlobalFilteredRows: any
    globalFilter: any
    setGlobalFilter: any
    t: any
}

// Define a default UI for filtering
function GlobalFilter({
    //this line  [eslint] 'preGlobalFilteredRows' is missing in props validation [react/prop-types]
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
    t,
}: Globalfilter) {
    const count = preGlobalFilteredRows.length
    const [value, setValue] = React.useState(globalFilter)
    const onChange = useAsyncDebounce((value: any) => {
        setGlobalFilter(value || undefined)
    }, 200)

    return (
        <span>
            {t("Search")}:{" "}
            <input
                className="form-control"
                value={value || ""}
                onChange={(e) => {
                    setValue(e.target.value)
                    onChange(e.target.value)
                }}
                placeholder={`${count} ${t("records")}...`}
            />
        </span>
    )
}

interface columnFilter {
    column: any
}

function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
}: columnFilter) {
    return (
        <input
            className="form-control mt-2"
            value={filterValue || ""}
            onChange={(e) => {
                setFilter(e.target.value || undefined)
            }}
            placeholder={`...`}
        />
    )
}

interface tableinterface {
    columns: any
    data: any
    dataCallback: any
    t: any
}

const displayHeaderForFilter = (header: any) =>
    header.replace("[min]", "").replace("[Wh]", "")

function Table({ columns, data, dataCallback, t }: tableinterface) {
    const defaultColumn = React.useMemo(
        () => ({
            Filter: DefaultColumnFilter,
        }),
        []
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state,
        preGlobalFilteredRows,
        setGlobalFilter,
        allColumns,
        visibleColumns,
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
        },
        useFilters,
        useGlobalFilter,
        useSortBy
    )

    useEffect(() => {
        dataCallback(rows, visibleColumns)
    }, [state])

    const generateSortingIndicator = (column: any) => {
        return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""
    }

    return (
        <React.Fragment>
            <div className="d-flex gap-2">
                {allColumns.map((column: any) => (
                    <div key={column.id}>
                        <label>
                            <input
                                type="checkbox"
                                {...column.getToggleHiddenProps()}
                            />{" "}
                            {displayHeaderForFilter(t(column.Header))}
                        </label>
                    </div>
                ))}
                <br />
            </div>
            <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
                t={t}
            />
            <table className="table" {...getTableProps()}>
                <thead>
                    {headerGroups.map((headerGroup: any) => (
                        <tr
                            key={headerGroup.id}
                            {...headerGroup.getHeaderGroupProps()}
                        >
                            {headerGroup.headers.map((column: any) => (
                                <th
                                    key={column.id}
                                    {...column.getHeaderProps()}
                                >
                                    <div
                                        className="mb-2"
                                        {...column.getSortByToggleProps()}
                                    >
                                        {t(column.render("Header"))}
                                        {generateSortingIndicator(column)}
                                    </div>
                                    <div>
                                        {column.canFilter
                                            ? column.render("Filter")
                                            : null}
                                    </div>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row: any, i: number) => {
                        prepareRow(row)
                        return (
                            <tr key={row.id} {...row.getRowProps()}>
                                {row.cells.map((cell: any) => {
                                    //console.log(cell)
                                    if (cell?.column?.id == "online") {
                                        const online = cell?.value === "online"
                                        return (
                                            <td
                                                key={cell.id}
                                                {...cell.getCellProps()}
                                            >
                                                <Badge
                                                    color=""
                                                    className={
                                                        online
                                                            ? "badge-soft-success"
                                                            : "badge-soft-danger"
                                                    }
                                                >
                                                    {online
                                                        ? "online"
                                                        : "offline"}
                                                </Badge>
                                            </td>
                                        )
                                    }
                                    if (
                                        cell?.column?.id?.startsWith("last") ||
                                        cell?.column?.id?.startsWith("created")
                                    ) {
                                        const timeStr = formatDateToLocalTime(
                                            cell?.value
                                        )
                                        return (
                                            <td
                                                key={cell.id}
                                                {...cell.getCellProps()}
                                            >
                                                {timeStr}
                                            </td>
                                        )
                                    }
                                    return (
                                        <td
                                            key={cell.id}
                                            {...cell.getCellProps()}
                                        >
                                            {cell.render("Cell")}
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </React.Fragment>
    )
}

const minTableHeight = 300

function DatatableTables({ data, info, title, t, mailHandler, mailUrl }: any) {
    const { width, height } = useWindowDimensions()

    const tableHeight = Math.max(minTableHeight, height - 340)

    document.title = t(title)

    const [tableData, setTableData] = useState<any>([])
    const [visibleColumns, setVisibleColumns] = useState<any>([])

    const [hasPermissionsSendMailReports, setHasPermissionsSendMailReports] =
        useState<boolean>(false)

    if (!hasPermissionsSendMailReports) {
        checkPermissions(
            haspermSendMailReports,
            setHasPermissionsSendMailReports
        )
    }

    const exportFileNameBase = title.replace(/\s+/g, "_")

    const csvTitle = `${exportFileNameBase}.csv`

    const mapBodyData = (tableRows: any, visibleColumns: any) => {
        return tableRows?.map((elt: any) =>
            visibleColumns?.map((elc: any) => {
                if (
                    elc?.id?.startsWith("last") ||
                    elc?.id?.startsWith("created")
                )
                    return formatDateToLocalTime(elt?.values[elc.id])
                return elt?.values[elc.id]
            })
        )
    }
    const dataCallback = (currentRows: any, visibleColumns: any) => {
        setTableData(currentRows)
        setVisibleColumns(visibleColumns)
    }

    const exportCSV = (tableRows: any, visibleColumns: any) => {
        return mapBodyData(tableRows, visibleColumns)
    }

    const headersTranslated = (visibleColumns: any) => {
        return visibleColumns.map((elc: any) => t(elc.Header))
    }

    const exportPDF = (tableRows: any, visibleColumns: any) => {
        const unit: any = "pt"
        const size: any = "A4" // Use A1, A2, A3 or A4
        const orientation: any = "landscape" // portrait or landscape
        const marginLeft = 40
        const doc: any = new jsPDF(orientation, unit, size)

        const font = openSansFontTTF

        doc.addFileToVFS("OpenSans-CondLight-normal.ttf", font)
        doc.addFont(
            "OpenSans-CondLight-normal.ttf",
            "OpenSans-CondLight",
            "normal"
        )
        doc.setFont("OpenSans-CondLight")

        doc.setFontSize(12)

        const content: any = {
            startY: 50,
            //font: font,
            styles: { font: "OpenSans-CondLight" },
            head: [headersTranslated(visibleColumns)],
            body: mapBodyData(tableRows, visibleColumns),
        }

        doc.text(title, marginLeft, 40)

        doc.autoTable(content)

        doc.save(`${exportFileNameBase}.pdf`)
    }

    const columns = React.useMemo(() => info.headers, [])

    return (
        <Card>
            <CardHeader>
                <div className="d-flex flex-wrap gap-2">
                    <CSVLink
                        data={exportCSV(tableData, visibleColumns)}
                        headers={headersTranslated(visibleColumns)}
                        type="button"
                        className="btn btn-secondary "
                        filename={csvTitle}
                    >
                        <span>CSV</span>
                    </CSVLink>
                    <button
                        onClick={() => exportPDF(tableData, visibleColumns)}
                        type="button"
                        className="btn btn-secondary buttons-copy buttons-html5"
                    >
                        <span>Pdf</span>
                    </button>
                    {!hasPermissionsSendMailReports ? null : (
                        <MailingTrigger
                            t={t}
                            mailHandler={mailHandler}
                            data={exportCSV(tableData, visibleColumns)}
                            header={headersTranslated(visibleColumns)}
                            mailUrl={mailUrl}
                        />
                    )}
                </div>
            </CardHeader>
            <CardBody
                style={{
                    height: `${tableHeight}px`,
                    maxHeight: `${tableHeight}px`,
                    overflowY: "auto",
                    overflowX: "auto",
                    minWidth: `480px`,
                }}
            >
                <Table
                    columns={columns}
                    data={data}
                    t={t}
                    dataCallback={dataCallback}
                />
            </CardBody>
        </Card>
    )
}

export default withTranslation()(DatatableTables)
