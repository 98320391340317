import React, { useEffect, useRef, useState } from "react"
import {
    Collapse,
    Alert,
    Container,
    Label,
    Input,
    Modal,
    ModalHeader,
    Row,
    FormGroup,
    Col,
    ModalBody,
    Form,
    UncontrolledAlert,
} from "reactstrap"
import Select from "react-select"

//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

import PresetChart from "./PresetChart"
import { parseFromDcu } from "../Dashboard/DeviceInfo/dcu.service"
import ErrorHttp from "../ErrorHttp"
import { Spinner } from "react-awesome-spinners"
import DeleteModal from "src/components/Common/DeleteModal"
import EditPresetModal from "./EditPresetModal"
import useWindowDimensions from "src/hooks/useWindowDimensions"
import { useFetchWithAbort } from "src/hooks/useFetchWithAbort"

import { createAuthHeader } from "../../helpers/logged_in_user"
import {
    haspermManagePresets,
    haspermDisplayPresets,
    checkPermissions,
} from "../../helpers/permission_check"

const graphMinHeight = 300

const heightWithoutGraph = 400

const PresetsContent = (props: any) => {
    const { width, height } = useWindowDimensions()

    const abortControllerRef = useRef<AbortController>()

    const [selectedOption, setSelectedOption] = useState<number | null>(0)
    const [hasPermissionsManagePresets, setHasPermissionsManagePresets] =
        useState(false)
    const [hasPermissionsDisplayPresets, setHasPermissionsDisplayPresets] =
        useState(false)

    const [refreshKey, setRefreshKey] = useState<number>(0)

    const [addedPreset, setAddedPreset] = useState<boolean>(false)

    const [editModalVisible, setEditModalVisible] = useState<boolean>(false)

    const isEdit = useRef(false)

    const [deleting, setDeleting] = useState<boolean>(false)

    const [submitFlag, setSubmitFlag] = useState<any>(null)

    const [postingError, setPostingError] = useState<boolean>(false)

    const [deleteSelected, setDeleteSelected] = useState<boolean>(null)

    const [deletingError, setDeletingError] = useState<boolean>(false)

    const [presets, setPresets] = useState<any>(null)

    const valueId = presets?.presets[selectedOption]?.value ?? 0

    useEffect(() => {
        setPostingError(false)
    }, [selectedOption, refreshKey, deleteSelected, presets])

    useEffect(() => {
        setDeletingError(false)
    }, [selectedOption, refreshKey, submitFlag, presets])

    if (!hasPermissionsManagePresets) {
        checkPermissions(haspermManagePresets, setHasPermissionsManagePresets)
    }
    if (!hasPermissionsDisplayPresets) {
        checkPermissions(haspermDisplayPresets, setHasPermissionsDisplayPresets)
    }

    const {
        data: deleteResult,
        isLoading: isDeletingPreset,
        error: errorDeletingPreset,
    } = useFetchWithAbort(`/api/presets?id=${valueId}`, {
        method: "DELETE",
        depends: [deleteSelected],
        ...createAuthHeader("managePresets"),
    })

    const {
        data: postResult,
        isLoading: isPosting,
        error: errorPost,
    } = useFetchWithAbort(`/api/presets?id=${valueId}`, {
        method: "POST",
        body: JSON.stringify({
            value: valueId,
            label: presets?.presets[selectedOption]?.label ?? "Unknown",
            dcu: presets?.dcus[selectedOption] ?? [],
        }),
        depends: [submitFlag],
        ...createAuthHeader("managePresets"),
    })

    useEffect(() => {
        if (!!errorPost) {
            setPostingError(true)
        } else {
            setPostingError(false)
        }
        setSubmitFlag(null)
    }, [postResult, isPosting, errorPost])

    const {
        data: presetsData,
        isLoading: isLoadingPresets,
        error: errorLoadingPresets,
    } = useFetchWithAbort(
        `/api/presets?refreshKey=${refreshKey}`,
        {
            ...createAuthHeader("displayPresets"),
        },
        abortControllerRef
    )

    useEffect(() => {
        if (
            hasPermissionsDisplayPresets &&
            !errorLoadingPresets &&
            !isLoadingPresets &&
            !!presetsData
        ) {
            setPresets(presetsData)
        }
    }, [presetsData, isLoadingPresets, errorLoadingPresets])

    useEffect(() => {
        if (hasPermissionsDisplayPresets && addedPreset) {
            setSelectedOption(presets?.presets?.length - 1)
            setAddedPreset(false)
        }
    }, [addedPreset])

    useEffect(() => {
        setDeleteSelected(null)
        if (!!deleteResult && !isDeletingPreset && !errorDeletingPreset) {
            setPresets(deleteResult)
        }
        if (!!deleteResult && presets?.presets?.length > 0) {
            setSelectedOption(0)
        }
        if (!!deleteResult && presets?.presets?.length === 0) {
            setSelectedOption(null)
        }
        if (!!errorDeletingPreset) {
            setDeletingError(true)
        } else {
            setDeletingError(false)
        }
    }, [deleteResult, isDeletingPreset, errorDeletingPreset])

    useEffect(() => {
        return () => {
            if (abortControllerRef.current) {
                abortControllerRef.current.abort()
            }
        }
    }, [])

    if (isLoadingPresets || isDeletingPreset || isPosting) {
        return <Spinner />
    }

    if (!hasPermissionsDisplayPresets) {
        return <ErrorHttp code="403" permission="displayPresets" />
    }

    if (errorLoadingPresets || errorDeletingPreset || errorPost) {
        return <ErrorHttp code="500" />
    }

    const currentPresetValue =
        presets?.presets[selectedOption] ?? presets?.presets[0]

    const currentPresetGraph = presets?.dcus[selectedOption] ?? presets?.dcus[0]

    const reloadDcu = () => {
        setRefreshKey((prevKey) => prevKey + 1)
    }

    const saveDcu = () => {
        setSubmitFlag(true)
    }

    const edit = () => {
        isEdit.current = true
        setEditModalVisible(true)
    }

    const addNew = () => {
        isEdit.current = false
        setEditModalVisible(true)
    }

    const deleteModal = () => {
        setDeleting(true)
    }

    const confirmedDeleteCurrent = () => {
        setDeleteSelected(true)
        setDeleting(false)
    }

    const currentInUse = !!currentPresetValue
        ? currentPresetValue?.inUse ?? true
        : false

    const currentName = currentPresetValue?.label ?? ""

    return (
        <>
            <Form>
                <Label className="form-label">
                    {props.t("PresetsSelectCurvePreset")}
                </Label>
                <Row>
                    <Col md={6}>
                        <Select
                            value={currentPresetValue}
                            onChange={(e: any) => {
                                const index = presets?.presets.findIndex(
                                    (item: any) => item.value === e.value
                                )
                                setSelectedOption(index)
                            }}
                            options={presets?.presets}
                            classNamePrefix="select2-selection"
                        />
                    </Col>
                    <Col md={3}>
                        {!hasPermissionsManagePresets ? null : (
                            <button
                                onClick={addNew}
                                type="button"
                                className="btn btn-success w-md"
                            >
                                {props.t("Add new")}
                            </button>
                        )}
                    </Col>
                </Row>
                <hr />

                {!!deletingError ? (
                    <Row>
                        <UncontrolledAlert color="danger">
                            {`${props.t(
                                "Error deleting preset"
                            )}: ${currentName}`}
                        </UncontrolledAlert>
                    </Row>
                ) : (
                    <></>
                )}

                {!!postingError ? (
                    <Row>
                        <UncontrolledAlert color="danger">
                            {`${props.t(
                                "Error saving preset"
                            )}: ${currentName}`}
                        </UncontrolledAlert>
                    </Row>
                ) : (
                    <></>
                )}

                <Row>
                    {currentInUse ? (
                        <Alert color="info">
                            {props.t("Preset in use and cannot be edited")}
                        </Alert>
                    ) : !hasPermissionsManagePresets ? null : (
                        <div className="d-flex flex-wrap gap-2">
                            <button
                                onClick={edit}
                                type="button"
                                className="form-inline btn btn-light w-md"
                            >
                                {props.t("Edit")}
                            </button>
                            <button
                                onClick={deleteModal}
                                type="button"
                                className="form-inline btn btn-danger w-md"
                            >
                                {props.t("Delete")}
                            </button>
                        </div>
                    )}
                </Row>
            </Form>
            <PresetChart
                t={props.t}
                height={Math.max(height - heightWithoutGraph, graphMinHeight)}
                data={parseFromDcu(currentPresetGraph)}
            />
            <div className="text-end">
                {!hasPermissionsManagePresets ? null : (
                    <button
                        onClick={reloadDcu}
                        type="button"
                        className="btn btn-light w-md me-2"
                    >
                        {props.t("Reload")}
                    </button>
                )}
                {!hasPermissionsManagePresets || currentInUse ? null : (
                    <button
                        onClick={saveDcu}
                        type="button"
                        className="btn btn-success w-md"
                    >
                        {props.t("Save")}
                    </button>
                )}
            </div>
            <div style={{ clear: "both" }}></div>
            <DeleteModal
                show={deleting}
                onDeleteClick={confirmedDeleteCurrent}
                onCloseClick={() => {
                    setDeleting(false)
                }}
                msg={`${props.t("Deleting preset:")} ${currentName}. ${props.t(
                    "Are you sure?"
                )}`}
            />
            <EditPresetModal
                show={editModalVisible}
                name={!!isEdit.current ? currentName : ""}
                isEdit={isEdit.current}
                dcus={!!isEdit.current ? currentPresetGraph : []}
                onSaveClick={(dcusRow, newName) => {
                    const presetsLength = presets?.presets?.length ?? 0
                    //silently assume they are always sorted asc
                    const maxId =
                        presetsLength > 0
                            ? parseInt(
                                  presets?.presets[presetsLength - 1].value
                              )
                            : 0
                    setPresets((prevData) => {
                        if (!!isEdit.current) {
                            const newData = {
                                presets: [...prevData.presets],
                                dcus: [...prevData.dcus],
                            }
                            newData.dcus[selectedOption] = dcusRow
                            if (!!newName) {
                                newData.presets[selectedOption].label = newName
                            }
                            return newData
                        } else {
                            const newPreset = {
                                label: newName,
                                value: maxId + 1,
                                inUse: false,
                            }
                            const newData = {
                                presets: [...prevData.presets, newPreset],
                                dcus: [...prevData.dcus, dcusRow],
                            }
                            return newData
                        }
                    })
                    if (!isEdit.current) {
                        setAddedPreset(true)
                    }
                    setEditModalVisible(false)
                }}
                onCloseClick={() => {
                    setEditModalVisible(false)
                }}
            />
        </>
    )
}

const Presets = (props: any) => {
    const subTitle = props.t("Presets")
    document.title = `${subTitle} | ${props.t("TITLE")}`

    return (
        <div className="page-content">
            <Container fluid>
                <Breadcrumbs title={subTitle} breadcrumbItem={subTitle} />
                <PresetsContent t={props.t} />
            </Container>
        </div>
    )
}

export default withTranslation()(Presets)
