import { title } from "process"
import React, { useState } from "react"

import { useTable, useFilters, useSortBy } from "react-table"

import { Card, CardBody, CardHeader, Container } from "reactstrap"
import useWindowDimensions from "src/hooks/useWindowDimensions"

interface columnFilter {
    column: any
}

function DefaultColumnFilter({
    column: { filterValue, setFilter },
}: columnFilter) {
    return (
        <input
            className="form-control mt-2"
            value={filterValue || ""}
            onChange={(e) => {
                setFilter(e.target.value || undefined)
            }}
            placeholder={`...`}
        />
    )
}

interface tableinterface {
    columns: any
    data: any
    callbackRowSelection: any
    selectedRowId: number | null
    t: any
}

function Table({
    columns,
    data,
    callbackRowSelection,
    selectedRowId,
    t,
}: tableinterface) {
    const defaultColumn = React.useMemo(
        () => ({
            Filter: DefaultColumnFilter,
        }),
        []
    )

    const handleRowClick = (e: any, id: number) => {
        callbackRowSelection(id)
    }

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        useTable(
            {
                columns,
                data,
                defaultColumn,
                autoResetSortBy: false,
                autoResetFilters: false,
            },
            useFilters,
            useSortBy
        )

    const getRowStyling = (id: number) => {
        if (id == selectedRowId)
            return {
                cursor: "pointer",
                backgroundColor: "lightgoldenrodyellow",
            }
        return { cursor: "pointer" }
    }

    const generateSortingIndicator = (column: any) => {
        return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""
    }

    return (
        <React.Fragment>
            <table className="table" {...getTableProps()}>
                <thead>
                    {headerGroups?.length > 1 ? (
                        headerGroups[1].headers.map((column: any) => (
                            <th
                                key={column.accessor}
                                {...column.getHeaderProps()}
                            >
                                <div
                                    className="mb-2"
                                    {...Object.assign(
                                        column.getSortByToggleProps(),
                                        { title: column.title }
                                    )}
                                >
                                    {t(column.render("Header"))}
                                    {generateSortingIndicator(column)}
                                </div>
                                {column.canFilter
                                    ? column.render("Filter")
                                    : null}
                            </th>
                        ))
                    ) : (
                        <></>
                    )}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row: any, i: number) => {
                        prepareRow(row)
                        return (
                            <tr
                                style={getRowStyling(row.id)}
                                onClick={(e) => handleRowClick(e, row.id)}
                                key={row.id}
                                {...row.getRowProps()}
                            >
                                {row.cells.map((cell: any) => {
                                    return (
                                        <td
                                            key={cell.id}
                                            {...cell.getCellProps()}
                                        >
                                            {cell.render("Cell")}
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </React.Fragment>
    )
}

interface datetablesinterface {
    devicesData: any
    selectedDeviceRowId: number | null
    callbackRowSelection: any
    t: any
}

const totalDevices = (devicesData: any) => {
    if (process.env.REACT_APP_TOTALDEVICES) {
        //to be able to hack total number of devices:
        return process.env.REACT_APP_TOTALDEVICES
    }
    return devicesData ? devicesData?.length : 0
}

function DatatableTables({
    devicesData,
    selectedDeviceRowId,
    callbackRowSelection,
    t,
}: datetablesinterface) {
    const { width, height } = useWindowDimensions()

    const heightWithoutTable = 320

    const minTableHeight = 160

    const tableHeight = Math.max(minTableHeight, height - heightWithoutTable)

    const noDevices = totalDevices(devicesData)

    const offlineDevices = devicesData
        ? devicesData.filter((d: any) => d["status"] == "offline").length
        : 0

    const onlineDevices = devicesData
        ? devicesData.filter((d: any) => d["status"] == "online").length
        : 0

    const hasPoleColumn = devicesData.some((d: any) => !!d["pole"])

    const headerStr =
        `${t("Total")}: ${noDevices} ` +
        `${t("Offline")}: ${offlineDevices} ` +
        `${t("Online")}: ${onlineDevices}`

    //const [sortingData, setSortingData] = useState<Object|null>({})
    const columns = React.useMemo(
        () => [
            {
                Header: "#",
                columns: [
                    {
                        Header: t("Device"),
                        accessor: "id",
                        sortingDesc: null,
                        title: t("Device"),
                    },
                    {
                        Header: t("Id"),
                        accessor: "device",
                        sortingDesc: null,
                        title: t("DeviceId"),
                    },
                    {
                        Header: t("Status"),
                        accessor: "status",
                        sortingDesc: null,
                        title: t("Online/offline status"),
                        Cell: (row: any) => (
                            <span>
                                <span
                                    style={{
                                        color:
                                            row.value === "online"
                                                ? "#57d500"
                                                : "#ff2e00",
                                    }}
                                >
                                    &#x25cf;
                                </span>{" "}
                                {row.value}
                            </span>
                        ),
                    },
                    {
                        Header: t("Address"),
                        accessor: "location",
                        sortingDesc: null,
                        title: t("Address"),
                    },
                    hasPoleColumn
                        ? {
                              Header: t("Pole no"),
                              accessor: "pole",
                              sortingDesc: null,
                              title: t("Pole no"),
                          }
                        : null,
                ].filter((d: any) => d != null),
            },
        ],
        []
    )

    return (
        <Card>
            <CardHeader>
                <h4>{headerStr}</h4>
            </CardHeader>
            <CardBody
                style={{
                    height: tableHeight,
                    maxHeight: tableHeight,
                    overflowY: "auto",
                }}
            >
                <Table
                    columns={columns}
                    data={devicesData}
                    callbackRowSelection={callbackRowSelection}
                    selectedRowId={selectedDeviceRowId}
                    t={t}
                />
            </CardBody>
        </Card>
    )
}

export default DatatableTables
