import React from "react"
import {
    Col,
    Row,
    FormGroup,
    Label,
    Input,
    Button,
    FormFeedback,
    Form,
} from "reactstrap"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

//i18n
import { withTranslation } from "react-i18next"
import { formatDateToLocalTime } from "src/helpers/format_date"

const readOnly = true

const Info = ({ deviceId, deviceData, t }: any) => {
    if (deviceId != null) {
        // Form validation
        const validation = useFormik({
            // enableReinitialize : use this flag when initial values needs to be changed
            enableReinitialize: true,

            initialValues: {
                type: "",
                manufacturer: "",
                power: "",
            },
            validationSchema: Yup.object({
                type: Yup.string().required(t("Please Enter Type")),
                manufacturer: Yup.string().required(t("Please Enter Producer")),
                power: Yup.number().required(
                    t("Please Enter Nominal Power in kW")
                ),
            }),
            onSubmit: (values) => {
                console.log("Submitting form mock @TODO. Values", values)
            },
        })

        const createdLocalTimeForDisplay =
            formatDateToLocalTime(deviceData?.created) ?? t("Not set")

        const address = deviceData?.location ?? t("<uknown>")

        const fixtureType = deviceData?.type ?? t("<uknown>")

        const manufacturer = deviceData?.manufacturer ?? t("<uknown>")

        const power = deviceData?.power ?? t("<uknown>")

        const description = deviceData?.description ?? t("<none>")

        return (
            <Form
                className="needs-validation"
                onSubmit={(e) => {
                    e.preventDefault()
                    validation.handleSubmit()
                    return false
                }}
            >
                <Row>
                    <FormGroup className="mb-3">
                        <label className="form-label">
                            {t("Created time")}
                        </label>
                        <div className="text-success">
                            {createdLocalTimeForDisplay}
                        </div>
                    </FormGroup>
                </Row>
                <Row>
                    <FormGroup className="mb-3">
                        <label
                            htmlFor="basicpill-address-input"
                            className="form-label"
                        >
                            {t("Address")}
                        </label>
                        {readOnly ? (
                            <div>{address}</div>
                        ) : (
                            <textarea
                                id="basicpill-address-input"
                                className="form-control"
                                placeholder={t("Enter Location Address")}
                                rows={2}
                            >
                                {address}
                            </textarea>
                        )}
                    </FormGroup>
                </Row>
                <Row>
                    <Col md="4">
                        <FormGroup className="mb-3">
                            <Label htmlFor="validationCustom03">
                                {t("Type of Light Fixture")}
                            </Label>
                            {readOnly ? (
                                <div>{fixtureType}</div>
                            ) : (
                                <Input
                                    name="type"
                                    placeholder="Type of Light Fixture"
                                    type="text"
                                    className="form-control"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                        validation.values.type || fixtureType
                                    }
                                    invalid={
                                        validation.touched.type &&
                                        validation.errors.type
                                            ? true
                                            : false
                                    }
                                />
                            )}
                            {validation.touched.type &&
                            validation.errors.type ? (
                                <FormFeedback type="invalid">
                                    {validation.errors.type}
                                </FormFeedback>
                            ) : null}
                        </FormGroup>
                    </Col>
                    <Col md="4">
                        <FormGroup className="mb-3">
                            <Label htmlFor="validationCustom04">
                                {t("Manufacturer")}
                            </Label>
                            {readOnly ? (
                                <div>{manufacturer}</div>
                            ) : (
                                <Input
                                    name="manufacturer"
                                    placeholder="Manufacturer"
                                    type="text"
                                    className="form-control"
                                    id="validationCustom04"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                        validation.values.manufacturer ||
                                        manufacturer
                                    }
                                    invalid={
                                        validation.touched.manufacturer &&
                                        validation.errors.manufacturer
                                            ? true
                                            : false
                                    }
                                />
                            )}
                            {validation.touched.manufacturer &&
                            validation.errors.manufacturer ? (
                                <FormFeedback type="invalid">
                                    {validation.errors.manufacturer}
                                </FormFeedback>
                            ) : null}
                        </FormGroup>
                    </Col>
                    <Col md="4">
                        <FormGroup className="mb-3">
                            <Label htmlFor="validationCustom05">
                                {t("Nominal Power")}
                            </Label>
                            {readOnly ? (
                                <div>{power}</div>
                            ) : (
                                <Input
                                    name="power"
                                    placeholder={t("Nominal Power")}
                                    type="text"
                                    className="form-control"
                                    id="validationCustom05"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.power || power}
                                    invalid={
                                        validation.touched.power &&
                                        validation.errors.power
                                            ? true
                                            : false
                                    }
                                />
                            )}
                            {validation.touched.power &&
                            validation.errors.power ? (
                                <FormFeedback type="invalid">
                                    {validation.errors.power}
                                </FormFeedback>
                            ) : null}
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <FormGroup className="mb-3">
                        <label
                            htmlFor="basicpill-address-input"
                            className="form-label"
                        >
                            {t("Description")}
                        </label>
                        {readOnly ? (
                            <div>{description}</div>
                        ) : (
                            <textarea
                                id="basicpill-address-input"
                                className="form-control"
                                placeholder={t("Enter Location Description")}
                                rows={2}
                            >
                                {description}
                            </textarea>
                        )}
                    </FormGroup>
                </Row>
                {!readOnly ? (
                    <Row>
                        <FormGroup className="mb-3">
                            <Button color="primary" type="submit">
                                {t("Update location info")}
                            </Button>
                        </FormGroup>
                    </Row>
                ) : (
                    <></>
                )}
            </Form>
        )
    }
    return <h4 className="card-title">{t("NoDeviceInfo")}</h4>
}

export default withTranslation()(Info)
